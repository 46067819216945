import axios, { AxiosInstance } from "axios";
import {} from "./interface";
import { iRes } from "./commonResponse";

export class ResData extends iRes {
    [x: string]: any;
}

const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    transformRequest: [
        (data) => {
            return JSON.stringify(data);
        },
    ],
    transformResponse: [
        (data) => {
            return JSON.parse(data);
        },
    ],
    headers: {
        Accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Pragma: "no-cache",
    },
});

const axiosCommonResponse = (url: string, data?: any) => {
    return new Promise<ResData>((resolve, reject) => {
        axiosInstance
            .post(url, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const axiosMethods = {
};

export default { axiosInstance, axiosMethods };
