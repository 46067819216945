import { createApp } from "vue";

import App from "./App.vue";
import axios from "./axios";
import { i18n } from "./i18n";
import router from "./router";
import { createPinia } from "pinia";
import { useStore } from "./pinia";
import 'aos/dist/aos.css'

import "./assets/css/reset.css";
import "./assets/css/header.css";
import "./assets/css/footer.css";
import "./assets/css/index.css";
import "./assets/css/guide.css";


import Header from "./components/Header.vue";


const app = createApp(App);

const useI18n = i18n();
app.use(useI18n);
app.use(router);
const pinia = createPinia();
app.use(pinia);

app.component("Header", Header);

app.mount("#app");

const store = useStore();

